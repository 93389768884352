import React from 'react'

export default function Logos(props) {
    return (
        <div className="logos flex shadow">
            <div className="logo-1">
                {props.logoOne}
            </div>
            <div className="logo-2">
                {props.logoTwo}
            </div>
            <div className="logo-3">
                {props.logoThree}
            </div>
            <div className="logo-4">
                {props.logoFour}
            </div>
            <div className="logo-5">
                {props.logoFive}
            </div>
            <div className="logo-6">
                {props.logoSix}     
            </div>
        </div>    
    )
}
