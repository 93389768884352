import React from 'react'

export default function Pillar(props) {
    return (
        <div className="pillar flex flex-col items-center my-5 md:my-20">
            <span className="mb-10 image-wrapper">{props.img}</span>
            <h5 className="text-blue-700">{props.title}</h5>
            <p className="text-center md:w-9/12 mx-auto">{props.description}</p>
        </div>
    )
}
