import React from "react"
import { Container, Grid, Button, Hidden }  from '@material-ui/core'
import { Link } from 'gatsby'

import SEO from "../components/theme/seo"
import Layout from "../components/theme/layout"

import HomeBanner from '../components/images/banners/home-banner'
import MobileBanner from '../components/images/banners/mobile-banner'
import Logos from '../components/logos'
import OneCol from '../components/one-col'
import Pillar from '../components/pillar'
import Parallax from "../components/parallax"
import TwoColRow from "../components/twocol-row"
import Slider from "../components/theme/slider"

import TireCTA from "../images/tires-cta-banner.jpg"
import BMWLogo from "../images/svg/bmw-logo.svg"
import AudiLogo from "../images/svg/audi-logo-new.svg"
import VWLogo from "../images/svg/vdub-logo.svg"
import PorscheLogo from "../images/svg/porsche-logo.svg"
import LandRoverLogo from "../images/svg/land-rover-logo.svg"
import Jaguar from "../images/svg/jaguar-logo.svg"
import WhyUs1 from "../images/why-us-1.jpg"
import WhyUs2 from "../images/why-us-2.jpg"
import WhyUs3 from "../images/why-us-3.jpg"
import OurServices from "../images/our-services.jpg"
import HomeBannerLg from "../images/home-banner-large.jpg"
import HomeBannerSm from "../images/home-banner-small.jpg"

const Home = () => (
  <Layout>
    <SEO title="Home" />
    <Hidden smDown>
      <Slider
        sliderImg={HomeBannerLg}
        title="JJ Automotive Insight"
        subTitle="We are your dealership alternative - specializing in European cars."
      />
    </Hidden>
    <Hidden mdUp>
      <Slider
        sliderImg={HomeBannerSm}
        title="JJ Automotive Insight"
        subTitle="We are your dealership alternative - specializing in European cars."
      />
    </Hidden>
    <Container>
      <Logos
        logoOne={<img src={BMWLogo} alt="BMW" />}
        logoTwo={<img src={AudiLogo} alt="Audi" />}
        logoThree={<img src={VWLogo} alt="VW" />}
        logoFour={<img src={PorscheLogo} alt="Porsche" />}
        logoFive={<img src={LandRoverLogo} alt="LandRover" />}
        logoSix={<img src={Jaguar} alt="Jaguar" />}
      />  
      <OneCol
        title="Why Choose JJ Automotive?"
      />
      </Container>
      <Container>
        <Grid container spacing={3} direction="row">
          <Grid item md={4} xs={12}>
            <Pillar
              img={<img src={WhyUs1} alt="Integrity" />}
              title="Integrity"
              description="High quality service & standards are our promise to you."
            />  
          </Grid>
          <Grid item md={4} xs={12}>
            <Pillar
              img={<img src={WhyUs2} alt="Full-Service" />}
              title="Full-Service"
              description="Trust us to provide bumper-to-bumper automotive repairs & maintenance."
            />  
          </Grid>
          <Grid item md={4} xs={12}>
            <Pillar
              img={<img src={WhyUs3} alt="Dealer Alternative" />}
              title="Dealer Alternative"
              description="Our warranty will meet or exceed your dealer warranty on parts & services. "
            />  
          </Grid>
        </Grid>
      </Container>
    
      <Parallax 
        img={TireCTA}
        imgPosition="center"
        title="Need your tires changed?"
        link="/contact-us"
        linkTitle="Book Today"
      />

      <Container>
        <OneCol
          title="Our Services"
          description="Audi, BMW, Mercedes, Volkswagen, Jaguar and MINI may be our specialties, but we proudly service all makes and models with the same care and attention."
        />
      </Container>

      <Container>
        <hr className="mt-20"/>
      </Container>

      <TwoColRow
          title="We offer a wide range of services including: repairs, maintenance, tire storage, auto detailing, rust proofing and more."
          link="/our-services"
          buttonTitle="View Our Services"
          bgImg={OurServices}
      />
      
  </Layout>
)

export default Home