import React from 'react'
import { Container, Grid, Button}  from '@material-ui/core'

export default function Slider(props) {
    return (
        <div className='white-text flex flex-col home-banner flex justify-center' style={{ 
            backgroundImage: `url(${props.sliderImg})`,
            backgroundSize: `cover`,
        }}>
            <Container className="h-full flex items-center justify-center">
                <Grid items>
                    <Grid item className="text-center mx-auto">
                        <h1>{props.title}</h1>
                        <h4 className="mt-5">{props.subTitle}</h4>                        
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}